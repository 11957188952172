import React, { useState, useEffect } from "react";
import Select from "react-select";

// Custom Styles for the Dropdown
const customStyles = {
    control: (base) => ({
        ...base,
        backgroundColor: "#fa6138",
        borderRadius: "12px",
        border: "2px solid #00067e",
        "&:hover": {
            borderColor: "#00067e", // Border color on hover
        },
        "&:focus": {
            outline: "none",
            boxShadow: "none"
        },
        padding: "5px",
        minHeight: "40px",
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "12px",
        backgroundColor: "white",
        border: "2px solid #00067e",
        color: "#00067e",
        padding: "0",
        outline: "none",
        overflow: "hidden"
    }),
    option: (base) => ({
        ...base,
        backgroundColor: "#fff",
        padding: "5px 16px",
        color: "#00067e",
        fontSize: "12px",
        fontWeight: "500",
    }),
    singleValue: (base) => ({
        ...base,
        color: "#00067e",
        fontSize: "12px",
        fontWeight: "500",
    }),
    placeholder: (base) => ({
        ...base,
        color: "#00067e",
        fontSize: "12px",
        fontWeight: "500",
    }),
};

export const StepTwo = ({ formData, setFormData }) => {
    const options = [
        {
            value: "1",
            label: "Karan",
            image: "https://i.pravatar.cc/150?img=1",
        },
        {
            value: "2",
            label: "Wrldkaran",
            image: "https://i.pravatar.cc/150?img=2",
        },
        {
            value: "3",
            label: "Rahul",
            image: "https://i.pravatar.cc/150?img=3",
        },
    ];
    const platformOptions = [
        { id: "Facebook", label: "Facebook", icon: "bi-facebook" },
        { id: "Instagram", label: "Instagram", icon: "bi-instagram" },
        { id: "YouTube", label: "YouTube", icon: "bi-youtube" },
        { id: "TikTok", label: "TikTok", icon: "bi-tiktok" }
    ];

    // Handle selected platforms
    const [selectedPlatform, setSelectedPlatform] = useState(formData.platformType || "");

    // Handle login status
    const [metaLoggedIn, setMetaLoggedIn] = useState(false);
    const [youtubeLoggedIn, setYouTubeLoggedIn] = useState(false);
    const [tiktokLoggedIn, setTikTokLoggedIn] = useState(false);

    // Handle account lists
    const [metaAccounts, setMetaAccounts] = useState([]);
    const [selectedMetaAccount, setSelectedMetaAccount] = useState(null);

    // Function to handle platform selection
    const handlePlatformSelect = (platform) => {
        console.log(platform, 'platform')
        setSelectedPlatform(platform);
        setFormData((prev) => ({ ...prev, platformType: platform }));

        // Reset login states when switching platforms
        setMetaLoggedIn(false);
        setYouTubeLoggedIn(false);
        setTikTokLoggedIn(false);
        // setMetaAccounts([]);
        // setFacebookPages([]);
        setSelectedMetaAccount(null);
        // setSelectedFacebookPage(null);
    };

    // Simulate Meta login & fetch accounts
    const handleMetaLogin = async () => {
        setMetaLoggedIn(true);
        console.log('===================')
        window.FB.login(
            
            (response) => {
                if (response.authResponse) {
                    console.log("✅ User logged in:", response);
                    const accessToken = response.authResponse.accessToken;
                    // setUserAccessToken(accessToken);
                    // setFormData((prev) => ({ ...prev, metaAccessToken: accessToken }));
                    // fetchMetaData(accessToken);
                    setMetaLoggedIn(true);
                } else {
                    console.error("❌ User canceled login or did not authorize.");
                }
            },
            { scope: "public_profile,email,ads_management,pages_show_list" }
        );
        // Simulate API call to get Meta account IDs
        setMetaAccounts([
            { value: "act_1245365333099644", label: "Account ID: act_1245365333099644" },
            { value: "act_1245365333099655", label: "Account ID: act_1245365333099655" },
            { value: "act_1245365333099666", label: "Account ID: act_1245365333099666" }
        ]);
    };

    const handleYouTubeLogin = () => setYouTubeLoggedIn(true);
    const handleTikTokLogin = () => setTikTokLoggedIn(true);
    // Default selected user
    const [selectedUser, setSelectedUser] = useState(options[1]);
    const handleChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };
    // console.log(selectedPlatform, 'selectedPlatforms')
    useEffect(() => {
        if (!window.FB) {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: "2888602711320285", // 🔹 Replace with your Facebook App ID
                    cookie: true,
                    xfbml: true,
                    version: "v22.0",
                });
            };

            // Load the SDK script dynamically
            (function (d, s, id) {
                let js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        }
    }, []);
    return (
        <div className="font-inter text-theme_clr1 mb-8 xl:mb-[61px]">
            <div className="heading text-white font-medium text-center mb-[28.5px]">
                <h5 className="text-[22.5px] mb-1">Choose your platform</h5>
                <div className="description text-white text-opacity-80 text-[15px]">Decide which platform you want your ads on, and which account to post from</div>
            </div>
            <div className="info-box bg-white rounded-xl p-4 lg:p-5">
                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[40%]">
                        <div className="title-name text-[22.69px] font-medium mb-[9px]">Choose your platform</div>
                        <div className="description font-medium text-theme_clr1 text-opacity-55 text-[15px]">
                            Choose the social media platform you’d like to advertise on (if you’re unsure select all available).
                        </div>
                    </div>
                    <div className="right-info lg:w-[60%]">
                        <div className="flex flex-col gap-2">
                            {platformOptions.map(({ id, label, icon }) => (
                                <div key={id} className="item relative">
                                    <input type="checkbox" className="peer hidden" id={id} autocomplete="off"
                                        checked={selectedPlatform === id}
                                        onChange={() => handlePlatformSelect(id)}
                                    />
                                    <label className="font-medium flex items-center gap-5 bg-transparent peer-checked:bg-theme_clr2 border-2 border-theme_clr1 rounded-2xl p-4" for={label}>
                                        <i className={`bi ${icon} text-2xl leading-none`}></i> {label}
                                    </label>
                                    {/* Icon to be shown when checkbox is checked */}
                                    <div className="icon absolute top-1/2 -translate-y-1/2 right-4 hidden peer-checked:block">
                                        <i className="bi bi-check-circle-fill text-theme_clr1 text-xl"></i>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <hr className="border-black my-4" />

                <div className="flex flex-col lg:flex-row gap-4 xl:gap-5">
                    <div className="left-info lg:w-[40%]">
                        <div className="title-name text-[22.69px] font-medium mb-2">Choose your account</div>
                        <div className="description font-medium text-theme_clr1 text-opacity-55 text-[15px]">
                            Choose the advertising account you want this campaign created under.
                        </div>
                    </div>
                    <div className="right-info lg:w-[60%]">
                        <div className="btn-wrapper flex gap-4 xl:gap-[41px] mb-3">
                            {selectedPlatform === "Facebook" || selectedPlatform === "Instagram" ? (
                                <button onClick={handleMetaLogin} className="text-xs font-medium text-theme_clr1 bg-transparent hover:bg-theme_clr1 hover:text-white focus:bg-theme_clr1 focus:text-white border-2 border-theme_clr1 rounded-lg transition-all px-4 py-[11px]">Login to Meta</button>
                            ) : null}
                            {selectedPlatform === "TikTok" ? (
                                <button onClick={handleTikTokLogin} className="text-xs font-medium text-theme_clr1 bg-transparent hover:bg-theme_clr1 hover:text-white focus:bg-theme_clr1 focus:text-white border-2 border-theme_clr1 rounded-lg transition-all px-4 py-[11px]">Login to TikTok</button>
                            ) : null}
                            {selectedPlatform === "YouTube" ? (
                                <button onClick={handleYouTubeLogin} className="text-xs font-medium text-theme_clr1 bg-transparent hover:bg-theme_clr1 hover:text-white focus:bg-theme_clr1 focus:text-white border-2 border-theme_clr1 rounded-lg transition-all px-4 py-[11px]">Login to Google</button>
                            ) : null}
                        </div>

                        <div className="account-info-wrapper flex flex-col gap-3">
                            <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                                <div className="heading mb-5">
                                    <div className="title text-sm font-medium mb-1">Select a Facebook advertising account</div>
                                    <div className="desc text-xs font-medium">Select a Facebook account you want this campaign created under</div>
                                </div>
                                <div className="select-wrapper text-theme_clr1 text-xs font-medium bg-theme_clr2 border-2 border-theme_clr1 rounded-xl p-3">
                                    <label htmlFor="accountID" className="block text-theme_clr1 text-xs font-medium mb-1 ps-1">1245365333099644</label>
                                    <select name="" id="accountID" className="w-full bg-transparent border-none focus:none outline-none">
                                        <option value="0">Account ID: act_1245365333099644</option>
                                        <option value="1">Account ID: act_1245365333099655</option>
                                        <option value="2">Account ID: act_1245365333099666</option>
                                    </select>
                                </div>
                            </div>
                            {/* Meta Account Selection */}
                            {metaLoggedIn && (
                                <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                                    <div className="heading mb-5">
                                        <div className="title text-sm font-medium mb-1">Select a Facebook page</div>
                                        <div className="desc text-xs font-medium">Choose a Facebook page you want your ad to be posted by</div>
                                    </div>
                                    <div className="select-wrapper">
                                        <div className="input-cover">
                                            <Select className="custom-select font-inter"
                                                onChange={handleChange}
                                                options={options}
                                                styles={customStyles}
                                                getOptionLabel={(info) => (
                                                    <div className="flex items-center">
                                                        <img src={info.image} alt={info.label} className="w-[32px] h-[32px] rounded-full mr-2" />
                                                        <span className="text-xs font-medium capitalize">{info.label}</span>
                                                    </div>
                                                )}
                                                value={selectedUser}
                                                isSearchable={true}
                                                placeholder="Select facebook page"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="bg-white border-2 border-theme_clr1 rounded-2xl p-4">
                                <div className="heading mb-5">
                                    <div className="title text-sm font-medium mb-1">Select an Instagram page</div>
                                    <div className="desc text-xs font-medium">Choose an Instagram page you want your ad to be posted by</div>
                                </div>
                                <div className="select-wrapper">
                                    <div className="input-cover">
                                        <Select className="custom-select font-inter"
                                            onChange={handleChange}
                                            options={options}
                                            styles={customStyles}
                                            getOptionLabel={(info) => (
                                                <div className="flex items-center">
                                                    <img src={info.image} alt={info.label} className="w-[32px] h-[32px] rounded-full mr-2" />
                                                    <span className="text-xs font-medium capitalize">{info.label}</span>
                                                </div>
                                            )}
                                            value={selectedUser}
                                            isSearchable={true}
                                            placeholder="Select facebook page"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
