import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import hamburger from '../assets/images/icons/hamburger.svg';
import SearchIcon from '../assets/images/icons/search.svg';
import CancelIcon from '../assets/images/icons/cross.svg';

export const Header = () => {
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 90); // Change 50 to your preferred scroll value
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // For Sidebar Drawer
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        document.body.classList.toggle('sidebar-open');
    };
    const token = localStorage.getItem("authToken");
    return (
        <header className={`sticky top-0 left-0 w-full z-40 ${isScrolled ? 'scrolled' : ''}`}>
            {/* Main Navbar Section */}
            <nav className="bg-theme_clr1 py-3 xl:py-4">
                <div className="container">
                    <div className="flex gap-4 xl:gap-8 items-center justify-between">
                        <div className="left-sec">
                            <div className="logo-wrapper">
                                <img src={require('../assets/images/logo.png')} alt="Logo" className="max-md:max-w-[120px] max-w-[145px] md:w-full" />
                            </div>
                        </div>

                        <button type="button" className="h-[40px] min-w-[56px] md:hidden flex items-center justify-center border border-orange rounded-full ms-auto px-5 py-3" onClick={toggleSidebar}>
                            <img src={hamburger} alt="Mobile Menu" className="icon" />
                        </button>

                        <div className={`right-section flex flex-wrap flex-col md:flex-row md:items-center gap-6 xl:gap-8 transition-transform transform ${isOpen ? 'max-md:-translate-x-0' : 'max-md:-translate-x-full'}`}>
                            <div className="md:hidden bg-theme_clr1 flex items-center justify-between w-full">
                                <Link to={'/'}><img src={require('../assets/images/logo.png')} alt="Logo" className="max-md:max-w-[120px]" /></Link>
                                <button onClick={toggleSidebar} className="text-darkblue">
                                    <img src={CancelIcon} alt="Cancel" className="block" />
                                </button>
                            </div>
                            <div className="menu-wrapper">
                                <ul className="list-none flex flex-wrap flex-col md:flex-row md:items-center gap-5 xl:gap-[36px]">
                                    <li>
                                        <Link to={'#'} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">About</Link>
                                    </li>
                                    <li>
                                        <Link to={'#'} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">Features</Link>
                                    </li>
                                    <li>
                                        <Link to={'#'} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">Blogs</Link>
                                    </li>
                                    <li>
                                        <Link to={'#'} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">Pricing</Link>
                                    </li>
                                    <li>
                                        <Link to={'#'} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-wrap gap-6 xl:gap-8">
                                <div className="hidden lg:block search-box relative">
                                    <span className='icon absolute start-[16px] top-1/2 transform -translate-y-1/2 z-1'>
                                        <img src={SearchIcon} alt="Search Icon" className='block' />
                                    </span>
                                    <input type="search" className='h-[40px] w-[160px] bg-white text-base ps-[45px] pe-4 py-2 text-black placeholder:text-black focus:outline-none rounded-[12px]' placeholder='Search' />
                                </div>
                                <div className="btn-wrapper">
                                    {!token ? (
                                        <>
                                            <button type='button' onClick={() => navigate('/login')} className='min-w-[84px] bg-white text-black text-sm font-manrope font-bold px-[16.5px] py-[9.5px] rounded-[12px] me-2'>Login</button>
                                            <button type='button' onClick={() => navigate('/signup')} className='min-w-[84px] bg-theme_clr2 text-white text-sm font-manrope font-bold px-[16.5px] py-[9.5px] rounded-[12px]'>Sign up</button>
                                        </>
                                    ) : (
                                        <button type='button' onClick={() => navigate('/dashboard')} className='min-w-[84px] bg-theme_clr2 text-white text-sm font-manrope font-bold px-[16.5px] py-[9.5px] rounded-[12px]'>Dashboard</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
