import React, { useState, useEffect, useMemo } from "react";
import MusicIcon from "../../../assets/images/admin/music.svg";
import ChartIcon from "../../../assets/images/admin/chart.svg";
import ClothsIcon from "../../../assets/images/admin/clothes.svg";

export const StepOne = ({ formData, setFormData }) => {
    // Memoized goal options to prevent re-creation
    const goalOptions = useMemo(() => [
        { id: 1, value: "More streams", icon: MusicIcon, desc: "Directly drive fans to stream your music" },
        { id: 2, value: "Spotify growth", icon: "bi bi-spotify", desc: "Increase your Spotify followers" },
        { id: 3, value: "Instagram growth", icon: "bi bi-instagram", desc: "Increase your Instagram followers" },
        { id: 4, value: "Playlist growth", icon: "bi bi-spotify", desc: "Grow your Spotify playlists" },
        { id: 5, value: "YouTube growth", icon: "bi bi-youtube", desc: "Drive YouTube views for your videos" },
        { id: 6, value: "Pre-Save Link", icon: ChartIcon, desc: "Promote your smartlink, song, video or website" },
        { id: 7, value: "Promote an event", icon: "event-icon", desc: "Sell tickets to your upcoming shows" },
        { id: 8, value: "Sell your merch", icon: ClothsIcon, desc: "Increase sales of your merch" }
    ], []);
    // State to track the selected item
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const savedSelection = goalOptions.find(option => option.value === formData.objective);
        if (savedSelection) setSelectedItem(savedSelection.id);
    }, [formData.objective, goalOptions]);

    // Handle item click to toggle selection
    const handleClick = (itemId, value) => {
        setSelectedItem(itemId);
        setFormData({ ...formData, objective: value });
    };

    return (
        <div className="font-inter">
            <div className="heading text-white font-medium text-center mb-[28.5px]">
                <h5 className="text-[22.5px] mb-1">Set your goal</h5>
                <div className="description text-white text-opacity-80 text-[15px]">
                    Choose a goal below and we’ll use your data to pre-select the desired preferences to achieve the best result.
                    You can also change the targeting if you would rather have more control.
                </div>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[12.5px]">
                {goalOptions.map(({ id, value, icon, desc }) => (
                    <div key={id}
                        className={`info-box h-full ${selectedItem === id ? "bg-theme_clr2" : "bg-white"} 
                        text-theme_clr1 text-[15px] font-medium text-center rounded-xl cursor-pointer p-4 md:p-[18px]`}
                        onClick={() => handleClick(id, value)}>
                        <div className="icon text-center mb-[19.5px]">
                            {typeof icon === "string" && (icon.startsWith("bi") || icon.startsWith('event')) ?
                                icon.startsWith("bi") ? <i className={`${icon} text-4xl leading-none`} /> :
                                    <svg width="36" height="36" viewBox="0 -5 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9272 2.87437C15.1152 1.04415 12.6495 0.00998664 10.0741 0H10C4.61522 0 0.25 4.36522 0.25 9.75V15C0.25 16.2426 1.25736 17.25 2.5 17.25H4C5.24264 17.25 6.25 16.2426 6.25 15V11.25C6.25 10.0074 5.24264 9 4 9H1.78375C2.17156 4.75177 5.7341 1.49979 10 1.5H10.0628C14.3107 1.51795 17.8457 4.76853 18.2191 9H16C14.7574 9 13.75 10.0074 13.75 11.25V15C13.75 16.2426 14.7574 17.25 16 17.25H17.5C18.7426 17.25 19.75 16.2426 19.75 15V9.75C19.7599 7.17412 18.7442 4.70022 16.9272 2.87437ZM4 10.5C4.41421 10.5 4.75 10.8358 4.75 11.25V15C4.75 15.4142 4.41421 15.75 4 15.75H2.5C2.08579 15.75 1.75 15.4142 1.75 15V10.5H4ZM18.25 15C18.25 15.4142 17.9142 15.75 17.5 15.75H16C15.5858 15.75 15.25 15.4142 15.25 15V11.25C15.25 10.8358 15.5858 10.5 16 10.5H18.25V15Z" fill="#00067e" />
                                    </svg>
                                : <img src={icon} alt={value} className="block mx-auto" />}
                        </div>
                        <div className="title-name mb-4">{value}</div>
                        <div className="title-desc">{desc}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
